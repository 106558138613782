























import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Country from '../../../../store/entities/country'
import Tag from "@/store/entities/jobcontext/tag";

@Component
export default class TagForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({type: Boolean, default: false}) public toEdit: boolean;
  tag: Tag = new Tag();

  created() {
  }

  save() {
    (this.$refs.tagForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await this.$store.dispatch({
          type: this.toEdit ? 'tag/update' : 'tag/create',
          data: this.tag
        });
        (this.$refs.tagForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
      }
    })
  }

  cancel() {
    (this.$refs.tagForm as any).resetFields();
    this.$emit('input', false);
  }

  visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      if (this.toEdit) {
        this.tag = Util.extend(true, {}, this.$store.state.tag.tag);
      } else {
        this.tag = new Tag();
      }
    }
  }

  tagRule = {
    name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
    sortIndex: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Sort index')), trigger: 'blur'}]
  }
}
