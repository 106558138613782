




































import {Component} from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import TagForm from './tag-form.vue'
import SortingModel from "@/store/entities/sortingmodel";

@Component({
  components: {TagForm}
})
export default class TagList extends AbpBase  {

  pagerequest: PageRequest = new PageRequest();

  async changeSort(data) {
    if (this.pagerequest.sorting == null) {
      this.pagerequest.sorting = [];
    }
    let item = this.pagerequest.sorting.find(x=>x.fieldName === data.key)
    if (item == null){
      let sortingModel = new SortingModel();
      sortingModel.fieldName = data.key;
      sortingModel.isDescendingDirection = data.order === "desc";
      this.pagerequest.sorting.push(sortingModel)
    }
    else{
      item.isDescendingDirection = data.order === "desc";
    }
    await this.getpage()
  }

  edit() {
    this.editModalShow = true;
  }

  createModalShow: boolean = false;
  editModalShow: boolean = false;

  get list() {
    return this.$store.state.tag.list;
  };

  get loading() {
    return this.$store.state.tag.loading;
  }

  create() {
    this.createModalShow = true;
  }

  async pageChange(page: number) {
    this.$store.commit('tag/setCurrentPage', page);
    await this.getpage();
  }

  async search() {
    await this.pageChange(1);
  }

  async pagesizeChange(pagesize: number) {
    this.$store.commit('tag/setPageSize', pagesize);
    await this.getpage();
  }

  async getpage() {

    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: 'tag/getAll',
      data: this.pagerequest
    })
  }

  get pageSize() {
    return this.$store.state.tag.pageSize;
  }

  get totalCount() {
    return this.$store.state.tag.totalCount;
  }

  get currentPage() {
    return this.$store.state.tag.currentPage;
  }

  columns = [{
    title: this.L('Name'),
    key: 'name',
    sortable: 'custom',
  }, {
    title: this.L('Sort index'),
    key: 'sortIndex',
    sortable: 'custom',
  },{
    title: this.L('Actions'),
    key: 'Actions',
    width: 150,
    render: (h: any, params: any) => {
      return h('div', [
        h('Button', {
          props: {
            type: 'primary',
            size: 'small',
            disabled: !this.hasEditPermission()
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: async () => {
              this.$store.commit('tag/edit', await this.$store.dispatch({
                type: 'tag/get',
                data: params.row.id
              }));
              this.edit();
            }
          }
        }, this.L('Edit')),
        h('Button', {
          props: {
            type: 'error',
            size: 'small',
            disabled: !this.hasDeletePermission()
          },
          on: {
            click: async () => {
              this.$Modal.confirm({
                title: this.L('Tips'),
                content: this.L('Delete Tag Confirm'),
                okText: this.L('Yes'),
                cancelText: this.L('No'),
                onOk: async () => {
                  await this.$store.dispatch({
                    type: 'tag/delete',
                    data: params.row
                  })
                  await this.getpage();
                }
              })
            }
          }
        }, this.L('Delete'))
      ])
    }
  }]

  async created() {
    await this.getpage();
  }

  hasAddPermission(){
    return this.hasPermission("Pages.Tags.Add");
  }

  hasEditPermission(){
    return this.hasPermission("Pages.Tags.Edit");
  }

  hasDeletePermission(){
    return this.hasPermission("Pages.Tags.Delete");
  }
}
